input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
*{
  font-family:Roboto;
}

body {
  overscroll-behavior-x: none;
}

::placeholder {
  font-family: "Montserrat";
  font-weight: bold;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.hideScroll::-webkit-scrollbar {
  height: 0;

}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d3d3d3;
}

@font-face {
  font-family: "logoFont";
  src: url(../fonts/ZenTokyoZoo-Regular.ttf);
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.footer-links,
.footer-logo,
.logo,
.navItem {
  cursor: pointer;
}
.MuiPaper-root.cards,
.business-credit,
.card-text,
.cash-management,
.contact-heading,
.copyright,
.drawerItem,
.footer-logo,
.get-started,
.heading1,
.heading2,
.navItem,
.features {
  text-align: center;
}
.about,
.contact {
  height: 100%;
  background: #3db1cc;
}
.business-credit {
  background: #f2f3f5;
  padding: 3rem 0 4rem;
}
.business-credit-heading,
.cash-management-heading {
  font-family: Montserrat;
  font-weight: 700;
  display: block;
  margin-bottom: 6rem;
}
.MuiGrid-root.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 95%;
  overflow: inherit;
  background: white;
}

.background,
.landing,
.login-container,
.signup-container,
.login-page,
.signup-page,
.main {
  height: 100%;
}
.MuiInputLabel-root.contact-form-field-label,
.read-more {
  font-family: Montserrat;
  color: #1f4690;
}
.contact-form {
  background: #fff;
  padding: 6rem 0;
}

.MuiTextField-root.contact-form-field {
  margin: 1rem 0;
}
a {
  text-decoration: none;
  color: inherit;
}
.footer {
  width: 100%;
  background-image: linear-gradient(#2f83d8, #3db1cc);
  padding-top: 3rem;
}
.footer-logo {
  align-items: center;
}
.footer-logo-text {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2.5rem;
  color: #dff6ff;
  visibility: hidden;
}
.footer-links-heading {
  font-family: Montserrat;
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #d3d3d3;
  color: #dff6ff;
}
.footer-links {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.1rem;
  color: #dff6ff;
}
.copyright {
  font-family: "Montserrat";
  font-size: 0.8rem;
  margin-top: 3rem;
  padding-bottom: 1rem;
  color: #dff6ff;
}
.main {
  background-color: #f5f5f4;
}
.landing {
  background-image: linear-gradient(#dff6ff, #f5ffff);
}
.background-wrap {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.background {
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}
.heading1 {
  font-size: 1.9rem;
  font-family: "Montserrat";
  font-weight: 400;
  color: black;
}
.heading2 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 3.5rem;
  color: black;
}

.mission {
  padding: 6rem;
  background: white;
}

.about-image {
  float: right;
  width: 40%;
  margin-left: 1rem;
  padding-bottom: 1rem;
}

.about-container {
  padding: 2rem 0rem;
  background: white;
}
.about-text-points-title,
.about-text-content-main {
  font-size: 1.4rem;
  font-family: "Montserrat";
  /* font-weight: 600; */
}

.about-text-points {
  font-size: 1.4rem;
  font-family: "Montserrat";
  margin: 1.5rem 0rem;
  /* font-weight: 600; */
}

.navItem {
  font-family: "Montserrat";
  font-weight: 600;
}

.navBar {
  padding-top: 10px;
  z-index: 10;
  background-image: linear-gradient(#2f83d8, #3db1cc);
}
.navBar {
  justify-content: center;
  position: fixed;
  color: #fcfcfc;
}
.navItem {
  font-size: 1.2rem;
}
.logo {
  font-family: "logoFont";
  font-size: 3rem;
  font-weight: normal;
  margin: 0;
}
.navBar2 {
  justify-content: center;
}

.MuiDrawer-root .MuiDrawer-paper {
  background-color: #11324d;
}

.drawerItem {
  font-size: 1.5rem;
  font-family: "Montserrat";
  color: white;
}
.features {
  padding: 4rem 0rem;
}

.features-section {
  margin: 4rem 0rem;
}
.contact-heading,
.about-heading,
.features-title,
.works-title {
  display: block;
  font-family: Montserrat, sans-serif;
  font-size: 3rem;
  font-weight: 600;
  display: block;
  color: #231955;
  text-align: center;
}

.features-title,
.works-title {
  margin-bottom: 7rem;
}

.features-heading {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 2.5rem;
  background: #2e75b6;
  padding: 1.5rem 0rem;
  color: white;
}

.MuiGrid-root.features-item {
  padding: 2rem 0rem;
  margin: 0.5rem 0rem;
}

.features-item-heading {
  font-family: "Montserrat";
  font-weight: 600;
  display: block;
  margin: 1rem 0rem;
}

.features-item-image {
  width: 100%;
  height: auto;
  max-width: 100px;
}
.works-step-text,
.features-item-text {
  font-family: "Montserrat";
  font-size: 1.3rem;
}

.works {
  background: #dff6ff;
  padding: 4rem 0rem 8rem;
}

.MuiGrid-root.works-step {
  background: inherit;
}

.MuiGrid-root.works-heading-number {
  font-size: 2rem;
  font-family: "Montserrat";
  background-image: linear-gradient(#2f83d8, #3db1cc);
  width: 50px;
  height: 50px;
  border-radius: 5rem;
  color: white;
}

.MuiGrid-root.works-heading,
.features-item-heading {
  font-size: 2rem;
}

.MuiGrid-root.works-heading {
  font-family: "Montserrat";
  font-weight: 600;
}

.company-icon {
  border: 1px solid black;
}

.section-heading {
  font-size: 3.5rem;
}
.working1 {
  font-family: Montserrat;
  font-weight: 700;
  margin: 0;
  display: block;
}
.working2 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
  max-width: 35rem;
  margin: 2rem;
  display: block;
}
.listItem,
.login-heading {
  font-weight: 600;
  font-family: Montserrat;
}
.listItem {
  font-size: 1.5rem;
  margin: 1rem;
}
.MuiPaper-root {
  margin: 0.5rem;
}
.MuiPaper-root.working-points:hover {
  transform: scale(1.05);
}
.working-image {
  width: 80%;
}

.login-page,
.signup-page {
  width: 100%;
}
.login-heading {
  font-size: 1.8rem;
  margin-bottom: 8rem;
}
.MuiInputLabel-root.form-label {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.8rem;
}
.login-field {
  border-radius: 50px;
}
.form-label .MuiOutlinedInput-root {
  border-radius: 50px;
  border: 1px solid #186090;
  color: #000;
  font-family: Montserrat;
  padding: 0.1rem 0.5rem;
  margin: 0.7rem 0;
  box-shadow: 3px 5px #d3d3d3;
}
.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.72rem;
}
.forgot-password {
  font-family: Montserrat;
  color: #30a8d8;
  font-weight: 600;
  font-size: 0.72rem;
}
.MuiButton-root.login-button,
.not-registered {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.72rem;
}
.MuiCheckbox-root.Mui-checked.checkbox {
  color: #30a8d8;
}
.MuiButton-root.login-button {
  border-radius: 50px;
  padding: 0.8rem 0;
  background: #30a8d8;
  margin: 2rem 0;
  border: 1px solid #000;
}
.login-image,
.signup-image {
  width: 100%;
  max-height: 100%;
}
.login-right {
  background-image: url("../images/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 0;
}
.signup-right {
  background-image: url("../images/signup-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 0;
}

.signup-heading {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Montserrat";
  margin-bottom: 3rem;
}
.MuiAlert-root.snack {
  font-family: "Montserrat";
}

.dashboard {
  height: 100%;
  width: 100%;
}

.dashboard-container {
  height: 100%;
}

.MuiGrid-root.dashboard-sidebar {
  background-image: linear-gradient(#2f83d8, #3db1cc);
  height: 100%;
}

.dashboard-sidebar-section {
  padding: 0rem 1rem;
}

.sidebar-logo {
  font-size: 2.5rem;
  margin: 2rem 0rem 4rem;
  display: block;
  text-align: center;
  color: white;
}

.MuiList-root.section-items {
  padding: 0rem 0rem 0.8rem 0.5rem;
}

.dashboard-section-heading,
.logout {
  font-family: "Montserrat";
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
}

.MuiGrid-root.show-sidebar {
  max-width: 16.5rem;
  height: 100%;
}

.MuiPaper-root.section-item-text {
  font-family: "Montserrat";
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.5rem;
  width: 100%;
}

.logout {
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  padding-left: 1rem;
}

.section-item-icon {
  width: 21px;
}

.sidebar-bottom-item {
  margin: 0.7rem 0rem;
}

.sidebar-bottom-icon {
  width: 28px;
}

.sidebar-bottom-text {
  color: white;
  font-family: "Montserrat";
}

.cashflows {
  max-height: 100vh;
  padding: 0rem 0.5rem;
}

.util-button-paper {
  display: inline-block;
  outline: 1px solid #d3d3d3;
  height: 100%;
  cursor: pointer;
}

.util-button-text {
  font-family: "Montserrat";
  font-weight: bold;
  padding: 0.5rem;
  padding-right: 0rem;
}

.cash-balance {
  text-align: center;
  background: #f5f6f7;
  border: 2px solid #d3d3d3;
  border-radius: 0.9rem;
  padding: 0.4rem 0.8rem;
}

.cash-balance-heading {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 0.9rem;
  display: block;
}

.cash-value {
  font-family: "Montserrat";
  font-weight: 1000;
  font-size: 1.4rem;
  display: block;
}

.delete-mock {
  background: #edf0f2;
  border: 2px solid #d3d3d3;
  border-radius: 0.5rem;
  padding: 0.2rem 0.8rem;
}

.delete-mock-text {
  font-family: "Montserrat";
  color: #c33636;
  font-weight: 600;
}

.topbar {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid #3491d5;
  background-color: white;
  z-index:5;
}

.company-name {
  font-family: "Montserrat";
  font-weight: 600;
  margin-left: 1rem;
}

.MuiGrid-root.settings-heading {
  font-size: 2rem;
  font-family: "Montserrat";
  font-weight: 600;
  margin-top: 3rem;
}

.MuiGrid-root.settings-padding {
  padding-left: 2rem;
}

.MuiGrid-root.settings-tabs {
  border-bottom: 1px solid #3491d5;
  margin-top: 1rem;
  overflow: hidden;
}

.MuiTab-root.settings-tab {
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 600;
  padding: 1rem 2rem 1rem;
}

.MuiGrid-root.settings-section-heading {
  font-family: "Montserrat";
  font-size: 1.7rem;
  font-weight: 600;
}

.MuiGrid-root.user-management-top {
  margin-top: 4rem;
}

.invite-user {
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}

.invite-user-text {
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 600;
  margin-left: 1rem;
}

.user-count {
  font-size: 1.2rem;
  font-family: "Montserrat";
  font-weight: 600;
}

.user-list {
  margin-top: 3rem;
}

.MuiTableCell-root.user-list-heading {
  font-size: 1.5rem;
  font-family: "Montserrat";
  font-weight: 600;
  border-bottom: 1px solid #3491d5;
  padding-left: 2rem;
}

.MuiTableCell-root.user-list-info {
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  color: gray;
  border-bottom: 1px solid #3491d5;
  padding-left: 2rem;
  overflow: auto;
  max-width: 40vw;
}

.password,
.edit-password,
.change-password {
  font-size: 1.2rem;
  font-family: "Montserrat";
  font-weight: bold;
}

.MuiGrid-root.password {
  margin-top: 3rem;
}
.change-password {
  font-weight: 600;
  color: #b3b3b3;
}
.edit-password {
  display: inline-block;
  margin-top: 0.5rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.modal-heading {
  font-size: 1.5rem;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 2rem;
}

.invite-user-submit {
  font-size: 1.2rem;
  font-family: "Montserrat";
  font-weight: 600;
  background-image: linear-gradient(#2f83d8, #3db1cc);
  padding: 0.8rem 1.5rem;
  color: white;
  cursor: pointer;
}

.period-label {
  font-family: "Montserrat";
  font-size: 1.2rem;
}

.MuiGrid-root.insights-padding {
  margin-left: 4rem;
}

.insights-heading {
  display: block;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 1rem;
  border-bottom: 1px solid black;
  padding-left: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.MuiGrid-root.insights-tabs {
  margin-top: 0rem;
}

.MuiTab-root.insights-tab {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}

.MuiGrid-root.expense-categories-column,
.MuiGrid-root.insights-table-column,
.MuiTableCell-root.cashflows-table-column,
.MuiTableCell-root.cashflows-table-category,
.MuiTableCell-root.cashflows-table-subcategory {
  font-family: "Montserrat";
  font-weight: 600;
}

.MuiGrid-root.expense-categories-field {
  font-family: "Montserrat";
}

.expense-percentage {
  background: #d9d9d9;
  height: 10px;
}

.expense-percentage-front {
  height: 10px;
  background: #39a2d1;
}

.MuiGrid-root.insights-table-column {
  padding-bottom: 1rem;
}

.MuiTableCell-root.cashflows-table-column,
.MuiTableCell-root.cashflows-table-category {
  background: #f5f6f7;
  padding: 1rem 0.5rem;
}
.MuiTableCell-root.cashflows-table-category {
  border-bottom: 1px solid #d3d3d3;
  max-width: 120px;
}
.MuiTableCell-root.cashflows-table-subcategory {
  padding-left: 3rem;
  max-width: 120px;
}

.MuiGrid-root.receivables-filters-bar {
  padding: 10px 20px;
  background: #fafafa;
  border: 1px solid gray;
  border-radius: 4px;
}

.MuiGrid-root.receivables-filters {
  font-family: "Montserrat";
  font-weight: bold;
  height: 100%;
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.receivables-warning {
  font-family: "Montserrat";
  font-weight: bold;
  background: #fff8f8;
  border: 1px solid #ff5280;
  padding: 15px 5px;
  border-radius: 4px;
}

.MuiTableCell-root.receivables-column-header {
  font-family: "Montserrat";
  font-weight: 600;
  border-bottom: 2px solid #d3d3d3;
}

.MuiTableCell-root.receivables-row-value {
  font-family: "Montserrat";
  font-weight: 600;
  border-bottom: 1px solid #d3d3d3;
}

.receivables-header {
  background: #f5f6f7;
}

.actions-icon {
  height: 30px;
  margin: 2px;
  cursor: pointer;
  /* opacity: 1; */
  /* transition: opacity 0.5s ease-in-out; */
}

/* .actions-icon:hover {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
} */

/* .box1,
.box2 {
  text-align: center;
  transition: 0.5s;
}
.box2 {
  opacity: 0;
  transform: translateX(20px);
}
.box-wrap:hover .box2 {
  opacity: 1;
  transform: translateX(0px);
  position: absolute;
}
.box-wrap:hover .box1 {
  opacity: 0;
  height: 0;
} */

.icon-text {
  font-family: "Montserrat";
  font-weight: 600;
}

.MuiMenuItem-root.filter-menu-item {
  font-family: "Montserrat";
  font-weight: 600;
}

.MuiMenuItem-root.filter-menu-filter {
  font-size: 0.8rem;
}

.MuiCheckbox-root.filter-menu-checkbox {
  padding: 0rem;
  padding-right: 0.5rem;
}

.MuiGrid-root.filter-button {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 0.8rem;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
}

.categories-padding {
  padding-left: 2rem;
}

.MuiGrid-root.categories-section {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  padding-right: 3rem;
  cursor: pointer;
}

.categories-heading {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1rem;
}

.categories-cateogry {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1rem;
}

.categories-accordion > .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.categories-accordion > .MuiAccordionSummary-content {
  margin: 0;
}

.MuiAccordion-root {
  margin: 0;
}

.MuiGrid-root.categories-button {
  font-size: 1.25rem;
  font-family: "Montserrat";
  font-weight: 600;
  background: #3594d5;
  color: white;
  border-radius: 4px;
  text-align: center;
  padding: 1rem 0rem;
  border: 1px solid black;
}

.history-modal {
  background: #f5f6f7;
}

.history-customer {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.5rem;
  border-bottom: 2px solid #d3d3d3;
}

.history-event {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1rem;
  border-bottom: 1px solid #186090;
}

.filter-column {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.2rem;
}

.filter-heading {
  font-family: "Montserrat";
  font-weight: 600;
}
.filter-label .MuiOutlinedInput-root {
  font-family: Montserrat;
  font-weight: 600;
}

.filter-label .MuiInput-root {
  font-family: Montserrat;
  font-weight: 600;
}

.filter-label .MuiSelect-select {
  padding-right: 0px;
}

.MuiGrid-root.add-action {
  background: black;
  color: white;
  font-family: "Montserrat";
  font-weight: 600;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.MuiGrid-root.delete-action {
  border: 2px solid black;
  font-family: "Montserrat";
  font-weight: 600;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.bills-pay {
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  background: #30a8d8;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
}

.MuiGrid-root.pay-invoice {
  color: white;
  background: #30a8d8;
  font-family: "Montserrat";
  font-weight: 600;
  padding: 10px 3rem;
  border-radius: 10px;
  cursor: pointer;
}

.MuiGrid-root.credit-top {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.2rem;
  color: white;
  background: #3594d5;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 4px;
}

.credit-padding {
  padding: 0rem 2rem;
}

.MuiGrid-root.whatsapp-submit {
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  background: black;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
}

.set-password {
  font-family: "Montserrat";
  font-size: 1.3rem;
  font-weight: bold;
}

/* CashFlow scrollbar */
.custom-scrollbar__container{
  overflow-x: scroll; 
  height: calc(100vh - 205px);
}

.custom-scrollbar__container .custom-scrollbar{
  overflow-x: visible;
}

@media only screen and (max-width: 1400px) {
  .features-title,
  .works-title {
    font-size: 2.2rem;
  }
  .section-heading {
    font-size: 2.8rem;
  }
  .listItem,
  .working2 {
    font-size: 1.3rem;
  }
  .working2 {
    max-width: 25rem;
  }
  .features-section {
    margin: 0rem;
  }
  .heading2 {
    font-size: 3rem;
  }
  .MuiGrid-root.insights-padding {
    margin-left: 2em;
  }
}
@media only screen and (max-width: 1200px) {
  .business-credit-heading {
    margin-bottom: 4rem;
  }

  .features-business-credit {
    margin-top: 2rem;
  }
  .features-title,
  .works-title {
    font-size: 2rem;
  }
  .works-title,
  .features-title {
    margin-bottom: 4rem;
  }
  .section-heading {
    font-size: 2.5rem;
  }
  .listItem,
  .working2 {
    font-size: 1.2rem;
  }
  .working2 {
    max-width: 22rem;
  }
  .working-image {
    width: 90%;
  }
  .login-heading {
    margin-bottom: 5rem;
  }
  .signup-heading {
    margin-bottom: 3rem;
  }
  .dashboard-sidebar-section {
    padding: 0rem;
  }
  .dashboard-section-heading,
  .logout {
    font-size: 0.9rem;
  }
  .MuiPaper-root.section-item-text {
    font-size: 0.7rem;
  }
  .sidebar-bottom-text {
    font-size: 0.8rem;
  }
  .MuiGrid-root.works-heading,
  .features-item-heading {
    font-size: 1.5rem;
  }
  .MuiGrid-root.features-item {
    padding: 1rem 0rem;
  }
  .features-section {
    margin: 4rem 0rem;
  }

  .works {
    padding: 4rem 0rem 2rem;
  }
  .about-text-points-title,
  .about-text-content-main,
  .about-text-points {
    font-size: 1.2rem;
  }
  .heading1 {
    font-size: 1.5rem;
  }
  .heading2 {
    font-size: 2.5rem;
  }
  .MuiGrid-root.insights-padding {
    margin-left: 1rem;
  }
  .MuiGrid-root.expense-categories-column,
  .MuiGrid-root.MuiGrid-root.expense-categories-field,
  .MuiGrid-root.insights-table-column,
  .MuiTableCell-root.cashflows-table-column,
  .MuiTableCell-root.cashflows-table-category,
  .MuiTableCell-root.cashflows-table-subcategory {
    font-size: 0.8rem;
  }
  .credit-padding {
    padding: 0rem 1rem;
  }
  .MuiGrid-root.credit-top {
    font-size: 1rem;
  }
  
  .custom-scrollbar__container{
    height: calc(100vh - 256px);
  }
}
@media only screen and (max-width: 1050px) {
  .section-heading {
    font-size: 2.2rem;
  }
  .listItem {
    font-size: 1.1rem;
  }
  .sidebar-logo {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 900px) {
  /* .dashboard-sidebar {
    padding: 0rem 0.8rem;
  } */
  .sidebar{
    display: none;
  }
  .layout-childern{
    width: 100%;
  }
  .business-credit-heading {
    margin-bottom: 2rem;
  }
  .cash-management-heading {
    margin-bottom: 3rem;
  }
  .footer-logo-text {
    font-size: 2rem;
  }
  .footer-links-heading,
  .mission-text {
    font-size: 1rem;
  }
  .footer-links {
    font-size: 0.9rem;
  }
  .mission {
    padding: 6rem 1rem;
  }
  .mission-points {
    font-size: 1.3rem;
  }
  .features-title,
  .works-title {
    font-size: 2rem;
  }
  .contact-heading,
  .about-heading {
    font-size: 2.5rem;
  }
  .working1 {
    margin-top: 2rem;
  }
  .section-heading {
    font-size: 1.8rem;
  }
  .listItem {
    font-size: 0.9rem;
  }
  .MuiPaper-root.working2 {
    margin: 1rem;
  }
  .working-image {
    width: 100%;
  }
  .util-button-text {
    font-size: 0.8rem;
  }
  .features-item-image {
    width: auto;
    height: 100%;
  }
  .features-section-image {
    width: 80%;
  }
  .MuiGrid-root.works-step {
    margin-bottom: 5rem;
  }
  .about-image {
    width: 45%;
  }
  .topbar {
    padding: 0.5rem;
  }
  .MuiGrid-root.settings-heading {
    margin-top: 1.5rem;
  }
  .MuiGrid-root.insights-padding {
    margin-left: 1rem;
  }

  .custom-scrollbar__container{
    height: calc(100vh - 275px);
  }
}

@media only screen and (max-width: 750px) {
  .cash-management-points {
    font-size: 1.2rem;
  }
  .cash-management-points2 {
    font-size: 1.5rem;
  }
  .features-section-image {
    width: 100%;
  }
  ::placeholder,
  .receivables-filters,
  .receivables-warning,
  .MuiTableCell-root.receivables-column-header,
  .MuiTableCell-root.receivables-row-value {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 700px) {
  .heading1 {
    font-size: 1.5rem;
  }
  .heading2 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .xs-none {
    display: none;
  }
  .features-item-image {
    width: 100%;
    height: auto;
  }
  .features-heading {
    font-size: 2.5rem;
  }
  .MuiGrid-root.works-heading-number {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
  .about-text-points {
    font-size: 1.2rem;
  }
  .about-image {
    width: 100%;
    padding: 2rem 0rem;
  }
  .company-name {
    margin-left: 0.5rem;
  }
  .topbar-search {
    max-width: 10rem;
  }
  .MuiGrid-root.user-management-top {
    margin-top: 1.5rem;
  }
  .MuiTableCell-root.user-list-heading {
    font-size: 1.3rem;
  }
  .categories-padding {
    padding-left: 1rem;
  }
  .MuiGrid-root.categories-button {
    margin-bottom: 0.5rem;
  }
  .history-icons {
    width: 30px;
  }
  /* .history-customer {
    font-size: 1.rem;
  } */
}
@media only screen and (max-width: 500px) {
  
  .heading1 {
    font-size: 1.3rem;
  }
  .heading2 {
    font-size: 2rem;
  }
  .MuiGrid-root.receivables-filters-bar {
    padding: 10px 5px;
  }
  .MuiGrid-root.receivables-filters {
    margin-right: 0.5rem;
  }
  .MuiTableCell-root.receivables-row-value {
    padding: 1rem 0.5rem;
  }

  .custom-scrollbar__container{
    height: calc(100vh - 325px);
  }
}
